import React, {Component} from 'react';
import lib from 'library'

import FooterContact2 from './FooterContact2'
import ItemGoogleMap from './ItemGoogleMap'


const styles ={
  footerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 250,
    //backgroundColor: 'rgba(57,169,71,1)',
    //fontFamily: 'HelveticaNeueLTStd-Lt',
  },
  container:{
    width: 1024,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  title: {
    color: 'rgba(53,107,179,1)',
    fontSize: 30,
    fontFamily: 'HelveticaNeueLTStd-Bd',
  },
  content: {
    color: 'black',
    fontSize: 22,
    fontFamily: 'HelveticaNeueLTStd-ThEx',
  }
}


class SectionContact extends Component {


  render(){
    return(
      <div style={styles.footerStyle}>
        <div style={styles.container}>

          <div style={{
              display: 'flex',
              flex: 1,
            }}>
            <FooterContact2 pathname={this.props.pathname} />
          </div>
          <div style={{
            display: 'flex',
            height: 500,
            minWidth: 320,
            flex: 1,
            backgroundColor: 'lightgray',
            }}>
            <ItemGoogleMap  />
          </div>



        </div>

      </div>
    )
  }
}

export default SectionContact
