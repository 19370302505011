import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';


const styles ={
  footerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //height: 80,
    //backgroundColor: 'rgba(57,169,71,1)',
    //fontFamily: 'HelveticaNeueLTStd-Lt',
  },
  title: {
    color: 'rgba(53,107,179,1)',
    fontSize: 30,
    fontFamily: 'HelveticaNeueLTStd-Bd',
  }
}


const AnyReactComponent = () => <img src={require('../assets/image/KSsmall.jpg')} alt='KSlogo' />;


class ItemGoogleMap extends Component {

  static defaultProps = {
      center: {
        lat: 22.374838,
        lng: 114.114254
      },
      zoom: 15
    };

  render(){
    return(

      <div style={{ height: 500, width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDJgpGxJMGQkV2BTvhTt0SW9Lxz_R1nMCo' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
        <AnyReactComponent
            lat={22.374838}
            lng={114.114254}
          />
        </GoogleMapReact>
      </div>

    )
  }
}

export default ItemGoogleMap
