import React, {Component} from 'react';
import lib from 'library'


import MdPinDrop from 'react-icons/lib/md/pin-drop';
import MdLocalPhone from 'react-icons/lib/md/local-phone';
import MdPhoneLocked from 'react-icons/lib/md/phone-locked';
import MdEmail from 'react-icons/lib/md/email';
import MdAccessTime from 'react-icons/lib/md/access-time';



const styles ={
  footerStyle : {
    display: 'flex',
    //justifyContent: 'center',
    //alignItems: 'center',
    flexDirection: 'column',
    height: 250,
    width: 300,
    padding: 15,
    //backgroundColor: 'lightgray',
  },
  title: {
    color: lib.st.COLOR_THEME,
    fontSize: 20,
    //marginBottom: 0,
    fontFamily: 'HelveticaNeueLTStd-Bd',
  },
  content: {
    color: lib.st.COLOR_THEME,
    fontSize: 16,
    paddingLeft: 10,
    paddingTop: 8,
    paddingBottom: 5,
    marginBottom: 0,
    fontFamily: 'HelveticaNeueLTStd-Lt',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }
}

const iconSize = 35

class FooterContact2 extends Component {

  render(){

    const lang = lib.lc.currentLang(this.props.pathname)

    return(
      <div style={styles.footerStyle}>
        <p style={styles.title} >{lc[lang].contactus}</p>
        <div style={styles.container}>
          <MdPinDrop color={lib.st.COLOR_THEME} size={lang == 'en' ? 55 : 35} />
          <p style={styles.content} >{lc[lang].address}</p>
        </div>

        <div style={styles.container}>
          <MdLocalPhone color={lib.st.COLOR_THEME} size={23} />
          <p style={styles.content} >+852 2771 0300</p>
        </div>

        <div style={styles.container}>
          <MdPhoneLocked color={lib.st.COLOR_THEME} size={23} />
          <p style={styles.content} >+852 2771 5778</p>
        </div>

        <div style={styles.container}>
          <MdAccessTime color={lib.st.COLOR_THEME} size={23} />
          <p style={styles.content} >{lc[lang].operation}</p>
        </div>

        <div style={styles.container}>
          <MdEmail color={lib.st.COLOR_THEME} size={23} />
          <a style={styles.content} href='mailto:info@kunshung.com' >info@kunshung.com</a>
        </div>


      </div>
    )
  }
}


const lc = {
  en:{
    contactus: 'Contact us',
    address: 'Flat 9, 15/F, Grand City Plaza, 1-17 Sai Lau Kok Road, Tsuen Wan, N.T., Hong Kong',
    operation: 'Mon to Fri: 09:00 - 18:00',
  },
  zh:{
    contactus: '䏈絡我們',
    address: '香港新界荃灣西樓角路1-17號新領域廣場15樓1509室',
    operation: '週一至週五: 09:00 - 18:00',
  }
}

export default FooterContact2
